.home{
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}
.home__image{

    width:100%;
    mask-image: linear-gradient(to bottom,rgba(0,0,0,1),rgba(0,0,0,0));
    z-index:-1;
    margin-bottom:-10px;
}
.home_row{
    display: flex;
    z-index: 1;
    margin-left: 5px;
    margin-right:5px;
}