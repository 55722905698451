
.product{
display: flex;
flex-direction: column;
max-height: 400px;
min-width: 100px;
margin: 10px;
padding: 20px;
width:100%;
justify-content: flex-end;
background-color:white;
z-index: index 1;
align-items: center;
}
.product_rating{
    display: flex;
}
.product>img{
max-height: 200px;
width: 100%;
object-fit: contain;
margin: 15px;
}
.product_info{
    text-align: left;
    height: 100px;
    margin-bottom: 15px;
}
.product_price{
    margin-top: 5px;
}
.product>button{
    background-color:#f0c14b;
    border: 1px solid;
    border-color: #a88734 #9c7e31 #846a29;
    

}