.header {
    background-color: #000000;
    display: flex;
    align-items: center;
    position:sticky;

}

.header__nav{
    display: flex;
    justify-content: space-evenly;
    
}
.header_option{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
}
.header__link{
    color: white;
    text-decoration: none;
}
.header_basket{
    display: flex;
    
    align-items:center;
    
}
.headerbasketcount{
    margin-left: 10px;
    margin-right: 10px;
}
.header_optionlineone{
    font-size: 10px;
    color:white;
}
.header_optionlinetwo{
    font-size: 13px;
    font-weight: 800;
    color:white;
}
.header__search
{
    display: flex;
flex: 1;
}
.bar
{
    height: 12px;
    padding: 10px;
    border:none;
    width: 100%;
    
}

.header__logo {
    width: 100px;
    object-fit: contain;
    margin-left: 20px;
    margin-right: 20px;
    
    

}
.icon
{
    padding:5px;
    height:22px;
    background-color: #cd9042;
}