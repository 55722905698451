.checkout{
    display:flex;
    padding:20px;
    background-color: white;
    height:max-content;
}
.checkout_add{
width: 100%;
height: 40%;
margin-bottom: 10px;

}
.checkout_title{
    margin-right: 10px;
    padding:10px;
    border-bottom: 1px solid lightgray;
}